.grid {
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 12px;
}

.gridItem {
  align-items: center;
  /* background: #B4DEDC; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  position: relative;
  row-gap: 40px;
  text-decoration: none;
}

.image {
  height: 100%;
  padding: 50px;
  width: 75%;
}